import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import { Button } from "./ui/button";
import { AutocompleteAddress } from "./ui/AutocompleteAddress";
import {
	Card,
	CardHeader,
	CardTitle,
	CardDescription,
	CardContent,
} from "./ui/card";
import { Check, Sun } from "lucide-react";
import type { EstimateEntityType } from "@/models/estimate";

const formSchema = z.object({
	address: z.string().min(1, "Please enter a valid address"),
});

type FormData = z.infer<typeof formSchema>;

export interface GetQuoteFormProps {
	googleMapsApiKey: string;
}

export function GetQuoteForm({ googleMapsApiKey }: GetQuoteFormProps) {
	const {
		setValue,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<FormData>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = async (data: { address: string }) => {
		const response = await fetch("/api/quote", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		});
		const result = (await response.json()) as EstimateEntityType;
		const url = result.slugParts?.reverse().join("/");
		// TODO Handle error
		if (url) {
			window.location.href = `/${url}`;
		}
	};

	const onAddressSelect = (address: string) => {
		setValue("address", address);
	};

	return (
		<Card className="w-full    !shadow-md border-neutralGray-200">
			<CardHeader className="text-center ">
				<CardTitle className="text-neutralGray-900 font-medium">
					Get a Free Solar Quote
				</CardTitle>
				<CardDescription className="text-neutralGray-500 text-lg !mt-[2px] !pt-0">
					Enter your address to get a free solar quote.
				</CardDescription>
			</CardHeader>
			<CardContent className="pb-0 px-0">
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col items-center gap-4 w-full"
				>
					<div className="flex-1 w-full px-8">
						<AutocompleteAddress
							googleMapsApiKey={googleMapsApiKey}
							onAddressSelect={onAddressSelect}
							error={errors.address?.message}
						/>
					</div>
					<motion.div className="w-full">
						<Button
							type="submit"
							size="lg"
							className="w-full !rounded-t-none py-6  text-lg  rounded-lg transition-all duration-300"
							disabled={isSubmitting}
						>
							{isSubmitting ? (
								<motion.div
									animate={{ rotate: 360 }}
									transition={{
										duration: 1,
										repeat: Number.POSITIVE_INFINITY,
										ease: "linear",
									}}
									className="inline-block mr-2"
								>
									<Sun className="w-5 h-5" />
								</motion.div>
							) : null}
							{isSubmitting ? "Calculating Savings..." : "Reveal Your Savings"}
						</Button>
					</motion.div>
				</form>
			</CardContent>
		</Card>
	);
}
