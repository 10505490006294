import type React from "react";
import { useState, useCallback, useEffect, useRef } from "react";
import usePlacesAutocompleteService from "../../hooks/usePlacesAutocompleteService/usePlacesAutocompleteService";
import { Input } from "./input";
import { Button } from "./button";
import { useDebounce } from "@uidotdev/usehooks";

interface AutocompleteAddressProps {
	googleMapsApiKey: string;
	onAddressSelect: (address: string) => void;
	error?: string;
}

export function AutocompleteAddress({
	googleMapsApiKey,
	onAddressSelect,
	error,
}: AutocompleteAddressProps) {
	const [inputValue, setInputValue] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [predictions, setPredictions] = useState<
		google.maps.places.AutocompletePrediction[]
	>([]);
	const debouncedSearchTerm = useDebounce(inputValue, 300);

	const { getPredictions } = usePlacesAutocompleteService({
		apiKey: googleMapsApiKey,
	});

	const handleSelect = useCallback(
		(placeId: string, description: string) => {
			onAddressSelect(description);
			setInputValue(description);
			setIsOpen(false);
		},
		[onAddressSelect],
	);

	const handleInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setInputValue(e.target.value);
			setIsOpen(true);
		},
		[],
	);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				wrapperRef.current &&
				!wrapperRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const fetchPredictions = async () => {
			if (debouncedSearchTerm) {
				const predictions = await getPredictions(debouncedSearchTerm);
				setPredictions(predictions);
			}
		};
		fetchPredictions();
	}, [debouncedSearchTerm, getPredictions]);

	return (
		<div ref={wrapperRef} className="relative w-full space-y-2">
			<Input
				type="text"
				autoComplete="off"
				placeholder="Enter your address"
				value={inputValue}
				onChange={handleInputChange}
				className="w-full bg-white text-lg font-normal py-5 rounded-lg"
			/>
			{error && <p className="text-red-500 text-sm">{error}</p>}
			{isOpen && predictions.length > 0 && (
				<div className="absolute z-20 w-full bg-white shadow-lg rounded-md mt-1 max-h-60 overflow-auto border border-gray-200">
					{predictions.map((prediction) => (
						<Button
							key={prediction.place_id}
							variant="ghost"
							className="w-full justify-start text-left text-sm px-2 py-4 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
							onClick={() =>
								handleSelect(prediction.place_id, prediction.description)
							}
						>
							<div className="flex items-center">
								<svg
									className="w-5 h-5 mr-2 text-secondary-200"
									fill="none"
									stroke="hsl(var(--yellow-vivid-600))"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										fill="hsl(var(--yellow-vivid-100))"
										strokeWidth={2}
										d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
									/>
									<path
										fill="white"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
									/>
								</svg>
								<span className="font-normal text-base">
									{prediction.description}
								</span>
							</div>
						</Button>
					))}
				</div>
			)}
		</div>
	);
}
