import { useEffect, useCallback, useRef } from "react";

interface UsePlacesAutocompleteServiceOptions {
	apiKey: string;
	libraries?: string;
	debounceTime?: number;
	options?: Partial<google.maps.places.AutocompletionRequest>;
	sessionToken?: boolean;
	language?: string;
}

export interface UsePlacesAutocompleteServiceReturn {
	getPredictions: (
		input: string,
	) => Promise<google.maps.places.AutocompletePrediction[]>;
	getQueryPredictions: (
		input: string,
	) => Promise<google.maps.places.QueryAutocompletePrediction[]>;
	refreshSessionToken: () => void;
}

export default function usePlacesAutocompleteService({
	apiKey,
	libraries = "places",
	options = {},
	sessionToken,
	language,
}: UsePlacesAutocompleteServiceOptions): UsePlacesAutocompleteServiceReturn {
	const serviceRef = useRef<google.maps.places.AutocompleteService | null>(
		null,
	);
	const sessionTokenRef =
		useRef<google.maps.places.AutocompleteSessionToken | null>(null);

	const loadGoogleMapsScript = useCallback(() => {
		if (window.google?.maps) return Promise.resolve();

		return new Promise<void>((resolve) => {
			const script = document.createElement("script");
			script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}${language ? `&language=${language}` : ""}`;
			script.onload = () => resolve();
			document.body.appendChild(script);
		});
	}, [apiKey, libraries, language]);

	useEffect(() => {
		loadGoogleMapsScript().then(() => {
			serviceRef.current = new google.maps.places.AutocompleteService();
			if (sessionToken) {
				sessionTokenRef.current =
					new google.maps.places.AutocompleteSessionToken();
			}
		});
	}, [loadGoogleMapsScript, sessionToken]);

	const getPredictions = useCallback((input: string) => {
		if (!serviceRef.current || !input) {
			return Promise.resolve<google.maps.places.AutocompletePrediction[]>([]);
		}

		return new Promise<google.maps.places.AutocompletePrediction[]>(
			(resolve) => {
				serviceRef.current?.getPlacePredictions(
					{
						input,
						...(sessionTokenRef.current
							? { sessionToken: sessionTokenRef.current }
							: {}),
						...{
							types: ["address"],
							componentRestrictions: { country: "us" },
						},
					},
					(results) => {
						resolve(results || []);
					},
				);
			},
		);
	}, []);

	const getQueryPredictions = useCallback((input: string) => {
		if (!serviceRef.current || !input) {
			return Promise.resolve<google.maps.places.QueryAutocompletePrediction[]>(
				[],
			);
		}

		return new Promise<google.maps.places.QueryAutocompletePrediction[]>(
			(resolve) => {
				serviceRef.current?.getQueryPredictions({ input }, (results) => {
					resolve(results || []);
				});
			},
		);
	}, []);

	const refreshSessionToken = useCallback(() => {
		if (window.google) {
			sessionTokenRef.current =
				new google.maps.places.AutocompleteSessionToken();
		}
	}, []);

	return {
		getPredictions,
		getQueryPredictions,
		refreshSessionToken,
	};
}
